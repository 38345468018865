import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { address } from "../../constants/address";

const Form = () => {

  const [formData, setFormData] = useState({
    USERNAME : '',
    PASSWORD : ''
  });
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleOnChange = (e)=>{
    setError(false)
     const {name, value} = e.target;
     setFormData((prevState) => ({
      ...prevState,
      [name] : value
     }))
  }

  const handleOnLogin = async ()=>{
    try {
      const response = await axios.post(`${address}/login/verify`,{USERNAME : formData.USERNAME, PASSWORD : formData.PASSWORD})
      if (response.status === 200) {
        const {accessToken} = response.data
        localStorage.setItem('accessToken', accessToken)
        console.log(response.data);
        navigate('/home')
      }
    } catch (error) {
      setError(true)
      console.log('Error in Logging in.', error)
    }
  }

  return (
    <div className="flex justify-center w-full">
      <div className="w-[50%] py-20">
        <p className="text-primary font-bask font-semibold text-3xl">
          Jaina Jewelleres
        </p>
        <p className="text-primary font-bask font-semibold text-lg">
          Since 1928.
        </p>

        <p className="text-white font-bask text-xl mt-12">Welcome.</p>

        <div className="my-10">
          <div className="bg-black/15 bg-opacity-5 w-full h-12 border-white rounded-[4px] overflow-hidden">
            <input
              type="text"
              name="USERNAME"
              placeholder="Username"
              value={formData.USERNAME}
              onChange={handleOnChange}
              className="w-full h-full px-4 bg-transparent placeholder:text-gray-200 text-white font-mont focus:outline-none focus:border-2 rounded-[4px]"
            />
          </div>
          <div className="mt-5 bg-black/15 bg-opacity-5 w-full h-12 border-white rounded-[4px] overflow-hidden">
            <input
              type="password"
              name="PASSWORD"
              placeholder="Password"
              value={formData.PASSWORD}
              onChange={handleOnChange}
              className="w-full h-full px-4 bg-transparent placeholder:text-gray-200 text-white font-mont focus:outline-none focus:border-2 rounded-[4px]"
            />
          </div>

            {error && <div className='text-fifth font-mont text-xs font-semibold mt-1'>
                Wrong credentials.
              </div>}
          <div className="mt-5 flex justify-between text-white font-bask text-[16px]">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="appearance-none focus:outline-none border-white border bg-transparent w-4 h-4 checked:bg-white checked:border-transparent"
              />
              <p>Stay logged in</p>
            </div>
            <div className="underline">Forgot Password</div>
          </div>
        </div>
        <button onClick={handleOnLogin} className="w-full h-12 bg-primary rounded-[4px] hover:bg-primary/90">
          <p className="font-bask text-white text-lg ">Login</p>
        </button>
      </div>
    </div>
  );
};

export default Form;
