import React, { useEffect, useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";

const StatementTable = ({ data, dfrom }) => {
  const [rowNumber, setRowNumber] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [balance, setBalance] = useState({
    credit : 0,
    debit : 0
  })

  useEffect(() => {
    setRowNumber(data.length);

        // Calculate total credit and debit
        const creditTotal = data.reduce((sum, row) => sum + (row.RCREDIT || 0), 0);
        const debitTotal = data.reduce((sum, row) => sum + (row.RDEBIT || 0), 0);
    
        setTotalCredit(creditTotal);
        setTotalDebit(debitTotal);

        if (creditTotal > debitTotal) {
            setBalance ((preValue)=>({
                ...preValue,
                credit : creditTotal - debitTotal,
                debit : 0
            }))
        } else {
            setBalance ((preValue)=>({
                ...preValue,
                credit : 0,
                debit : debitTotal - creditTotal
            }))
        }
  }, [data]);

  return (
    <div className="border h-full rounded-[15px] shadow-custom-light overflow-hidden">
      {/* Header */}
      <div
        className={`bg-sixth flex text-white font-mont font-semibold text-sm ${
          rowNumber > 8 && "pr-[6px]"
        }`}
      >
        <div className="w-[6%] py-2 px-3"></div>
        <div className="w-[13%] py-2 px-3">Inv Date</div>
        <div className="w-[40%] py-2 px-3">Narration</div>
        <div className="w-[13%] py-2 px-3">Sale Amt.</div>
        <div className="w-[14%] py-2 px-3 text-right">Credit</div>
        <div className="w-[14%] py-2 px-3 text-right">Debit</div>
        <div className="py-2 px-4 hidden">TS</div> {/* Hidden column */}
        <div className="py-2 px-4 hidden">MRN</div> {/* Hidden column */}
      </div>

      <div className="h-[270px] overflow-y-auto font-mont font-bold text-sm">
        {data.length > 0 ? (
            data.map((row, i) => (
                <div
                  key={row.MRN}
                  className={`flex font-semibold text-sm hover:bg-sixth/30 ${
                    i % 2 === 0 ? "bg-sixth/10" : "bg-sixth/5"
                  }`}
                >
                  <div className="w-[6%] h-[30px] flex items-center px-3">
                    {row.TS === "M" && (
                      <button>
                        <EditNoteIcon fontSize="small" />
                      </button>
                    )}
                  </div>
                  <div className="w-[13%] h-[30px] flex items-center px-3">
                    {row.INVDATE === null ? new Date(dfrom).toLocaleDateString() : new Date(row.INVDATE).toLocaleDateString()}
                  </div>
                  <div className="w-[40%] h-[30px] flex items-center px-3">
                    {row.NARRATION}
                  </div>
                  <div className="w-[13%] h-[30px] flex items-center px-3">
                    {row.SALEAMT}
                  </div>
                  <div className="w-[14%] h-[30px] flex items-center px-3 justify-end">
                    {row.RCREDIT}
                  </div>
                  <div className="w-[14%] h-[30px] flex items-center px-3 justify-end">
                    {row.RDEBIT}
                  </div>
                  <div className="hidden">{row.TS}</div> {/* Hidden column */}
                  <div className="hidden">{row.MRN}</div> {/* Hidden column */}
                </div>
              ))
        ) : (
            <div className="">
                <img className="w-80 mx-auto" src="/table/tablebg.jpg" alt=""/>
                <div className="text-center font-mont font-sm font-semibold text-gray-400">No statement generated yet.</div>
            </div>
        )
          }
      </div>

      <div className={`flex h-[30px] items-center justify-end font-bold font-mont text-sm border-t ${rowNumber > 8 && "pr-[6px]"}`}>
        <div className="w-[13%] px-3">
          {"Total"}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right">
          {totalCredit}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right">
          {totalDebit}
        </div>
      </div>

      <div className={`flex h-[30px] justify-end font-bold font-mont text-sm ${rowNumber > 8 && "pr-[6px]"}`}>
        <div className="w-[13%] flex items-center px-3 border-t-2 border-sixth">
          {"Balance"}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right border-t-2 border-sixth">
          {balance.credit}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right border-t-2 border-sixth">
          {balance.debit}
        </div>
      </div>
    </div>
  );
};

export default StatementTable;
