import { createSlice } from "@reduxjs/toolkit";

const imageSlice = createSlice({
    name : 'imageSlice',
    initialState : [] ,
    reducers:{
        addImage : (state, action) =>{
            state.push(action.payload)
        },
        deleteImage : (state, action) =>{
            state.splice(action.payload,1)
        }
    }
})

export const {addImage, deleteImage} = imageSlice.actions;
export default imageSlice.reducer;