import React, { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const ToggleButton = ({method}) => {
  const [activeButton, setActiveButton] = useState(0);

  const handleSwitch = (activebtn, option)=>{
     setActiveButton(activebtn);
     method(option);
  }

  return (
    <div className="flex font-mont font-semibold text-sm">
      <button
        onClick={()=>{handleSwitch(1, 'credit')}}
        className={`flex w-[180px] space-x-1 justify-center items-center h-[40px] border border-black/25 rounded-l-[5px] ${
          activeButton === 1 ? "bg-third text-white" : "text-black/70"
        }`}
      >
        <AddCircleOutlineOutlinedIcon fontSize="small" />{" "}
        <p>Points Allocation</p>
      </button>

      <button
        onClick={()=>{handleSwitch(2, 'debit')}}
        className={`flex w-[180px] space-x-1 justify-center items-center h-[40px] border-l-0 border border-black/25 rounded-r-[5px] ${
          activeButton === 2 ? "bg-fifth text-white" : "text-black/70"
        }`}
      >
        <RemoveCircleOutlineOutlinedIcon fontSize="small" />{" "}
        <p>Points Deduction</p>
      </button>
    </div>
  );
};

export default ToggleButton;
