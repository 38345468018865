import React from "react";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const SubHeader = ({ text }) => {
  return (
    <div className="flex items-center justify-between mb-4 py-2 pr-4 border-b-2 border-black">
      <div className=" font-extrabold font-mont text-primary ">
        <ViewHeadlineOutlinedIcon /> {text}
      </div>
      <div>
        <div className="flex space-x-1 items-center py-2 text-primary font-bold font-mont text-sm  ">
          <AccountCircleOutlinedIcon />
          <p>Name</p>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
