import React, { useState } from "react";
import SubHeader from "../customComponents/SubHeader";
import CustomerSearchBox from "../customComponents/CustomerSearchBox";
import ToggleButton from "../customComponents/ToggleButton";
import { TextField } from "@mui/material";
import axios from "axios";
import { address } from "../../constants/address";

const LoyaltyPoints = () => {
  const [formData, setFormData] = useState({
    CUSTNAME: "",
    MOBILENO: "",
  }); // Setting form data received from search box

  const [pointsInfo, setPointsInfo] = useState({
    POINTS: '',
    NARRATION: "",
    PMD: new Date().toISOString().split("T")[0], // for sql -> .toLocaleDateString('en-GB')
  });

  const [method, setMethod] = useState(""); // will come add or minus from ToggleButton

  const handlePointInfoChange = (e) => {
    const { name, value } = e.target;
    // For Points, ensure the input is a valid whole number
    if (name === "POINTS" && !/^\d*$/.test(value)) {
      return; // If it's not a valid number, don't update the state
    }
    // Update the state for Points, Narration, and PointModifyDate
    setPointsInfo((prevValue) => ({
      ...prevValue,
      [name]: value, // Dynamically set the value for the corresponding field
    }));
  };

  const handleMethod = (value) => {
    setMethod(value);
  };

  const handleUpdate = async()=>{
    try {
      const token = localStorage.getItem('accessToken')
      const response = await axios.post(`${address}/customers/update-points`,{
       MOBILENO : formData.MOBILENO,
       CUSTNAME : formData.CUSTNAME,
       method : method,
       pointsInfo : pointsInfo
      },{headers:{Authorization:`Bearer ${token}`}})
      if (response.status === 200) {
        console.log(response.data.message)
      }
    } catch (error) {
      console.log('Error in updating poitns.')
    }
  }

  return (
    <div className="w-[1100px] mx-auto py-2">
      <SubHeader text={"Edit customer balance"} />
      <div className="w-full mx-auto rounded-[10px] mt-16  shadow-custom-dark">
        <div className="w-full flex h-[500px]">
          <div className="flex w-[35%] items-center bg-white rounded-l-[10px]">
            <img
              className="w-full mx-auto rounded-l-[10px]"
              src="/forms/formbg.jpg"
              alt=""
            />
          </div>
          <div className="w-[65%] py-6 px-6">
            <div className="w-full h-full px-10 py-4 rounded-[20px]">
              <div className="font-mont font-semibold border-b pb-2">
                Modify Loyalty Points
              </div>

              <div className="mt-8">
                <div className="w-full bg-white">
                  <CustomerSearchBox
                    getValue={(value) => {
                      setFormData({...value,});
                    }}
                  />
                </div>

                <div className="mt-6 flex justify-between">
                    <TextField
                      sx={{ width: 200 }}
                      value={formData.MOBILENO}
                      label="Mobile no."
                      variant="standard"
                      slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
                    />
                    <TextField
                      sx={{ width: 360 }}
                      value={formData.CUSTNAME}
                      label="Name"
                      variant="standard"
                      slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
                    />
                  </div>

                <div>
                  <div className="mt-6 flex justify-between items-end">
                    <TextField
                      name="PMD"
                      sx={{ width: 200 }}
                      value={pointsInfo.PMD}
                      label="Date of modify"
                      variant="standard"
                      onChange={handlePointInfoChange}
                      type="date"
                    />

                    <ToggleButton
                      method={(value) => {
                        handleMethod(value);
                      }}
                    />
                  </div>

                  <div className="mt-6 flex justify-between">
                    <TextField
                      name="POINTS"
                      sx={{ width: 200 }}
                      value={pointsInfo.POINTS}
                      label="Loyalty Points"
                      variant="standard"
                      onChange={handlePointInfoChange}
                    />
                    <TextField
                      name="NARRATION"
                      sx={{ width: 360 }}
                      value={pointsInfo.NARRATION}
                      label="Narration"
                      variant="standard"
                      onChange={handlePointInfoChange}
                    />
                  </div>

                  <div>
                    <button 
                    onClick={handleUpdate}
                    disabled={formData.MOBILENO === '' || pointsInfo.POINTS === '' || method === ''}
                    className="mt-8 py-2 px-8 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70">
                      Update
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyPoints;
