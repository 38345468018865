import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { address } from "../../constants/address";

const FreeSolo = ({getValue})=> {

  const [options, setOptions] = useState([]); // option array coming from searching

  const getCustomerList = async (value) => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        `${address}/customers/get-all-customers`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { search: value },
        }
      );
      if (response.status === 200) {
        const list = response.data;
        //console.log(list);
        setOptions(list);
      }
    } catch (error) {
      console.log("Error while loading customers " + error);
    }
  };
  
  const handleOnChange = async (value)=>{  // Get info of cutomer for selected option from list
    //console.log(value)
   getValue(value)
  }

  const handleSearch = (e)=>{  // Get searched customer list 
     const {value} = e.target
         value && getCustomerList(value)
  }

  return (
    <Autocomplete
  size="small"
  freeSolo
  autoHighlight
  onChange={(event, newValue) => {
    // newValue will now contain the entire selected object (e.g., { Name, PhoneNumber })
    newValue && handleOnChange(newValue);
  }}
  onInputChange={handleSearch}  // For tracking input typing
  options={options}  // Pass the original customer objects
  getOptionLabel={(option) => `${option.CUSTNAME}   (+91 ${option.MOBILENO})`}  // Control how options are displayed
  renderInput={(params) => (
    <TextField
      {...params}
      label="Search customer Mob no"
    />
  )}
/>
  );
}

export default FreeSolo;
