import ImageUpload from "../customComponents/ImageUpload";
import ImageShow from "../customComponents/ImageShow";
import "../../constants/customScrollbar.css";
import SubHeader from "../customComponents/SubHeader";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import { useSelector } from "react-redux";

const MyApp = () => {

  const imageArray = useSelector((store)=>(store?.images))
  imageArray && console.log(imageArray)

  return (
    <div className="py-2 mx-auto w-[1100px]">
      <SubHeader text={"Customize Mobile App"} />
       
       <div>
       <div className="flex items-center space-x-1 mt-12 mb-3 font-semibold font-mont text-primary">
        <ViewCarouselOutlinedIcon /> <p>Customize Banner</p>
      </div>
      <div className="flex space-x-20 px-6  bg-fourth/20 rounded-[10px] border">
        <div className="flex-shrink-0">
          <ImageUpload text={'Add to Banner'}/>
        </div>
        <div className="flex w-full px-4 bg-white space-x-4 my-6 rounded-[10px] py-6 overflow-x-auto shadow-custom-light">
          {imageArray.length > 0 ? (
            imageArray.map((image, i) => <ImageShow key={i} src={image.imageUrl} index={i}/>)
          ) : (
            <p className="w-full my-auto text-primary/40 text-center font-mont font-semibold">
              No images found. Upload images for banner.
            </p>
          )}
        </div>
      </div>
       </div>

       <div className="mt-12">
       <div className="flex items-center space-x-1 mt-12 mb-3 font-semibold font-mont text-primary">
        <ViewCarouselOutlinedIcon /> <p>Customize e-catalogue</p>
      </div>
      <div className="flex space-x-20 px-6  bg-fourth/20 rounded-[10px] border">
        <div className="flex-shrink-0">
          <ImageUpload text={'Add to e-catalogue'}/>
        </div>
        <div className="flex w-full px-4 bg-white space-x-4 my-6 rounded-[10px] py-6 overflow-x-auto shadow-custom-light">
          {imageArray.length > 0 ? (
            imageArray.map((image, i) => <ImageShow key={i} src={image.imageUrl} index={i}/>)
          ) : (
            <p className="w-full my-auto text-primary/40 text-center font-mont font-semibold">
              No images found. Upload images for e-catalogue.
            </p>
          )}
        </div>
      </div>
       </div>

    </div>
  );
};

export default MyApp;
