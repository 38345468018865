import React, { useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { addImage } from "../../redux/imageSlice";

const ImageUpload = ({text}) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");

  const chooseRef = useRef();
  const dispatch = useDispatch();

  const handleChoose = () => {
    setError(""); // Reset any previous error
    chooseRef.current.click();
  };

  const handleUploadImage = () => {
    const file = chooseRef.current.files[0];
    if (file) {
      /*if (file.size > 1024 * 1024) { // Check if file size exceeds 1 MB
        setError("File size should be less than 1 MB");
        return;
      }*/
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      console.log(file,imageUrl)
    }
  };

  const handleAddToBanner = () => {
    const file = chooseRef.current.files[0];
    const imageUrl = URL.createObjectURL(file);

     dispatch(addImage({imageUrl:imageUrl}))
     handleDeleteImage();
  }

  const handleDeleteImage = () => {
    setImage(null);
    chooseRef.current.value = null; // Clear the file input
  };

  return (
    <div className="w-64 px-4 py-4 my-6 bg-white shadow-custom-light rounded-[20px]">
      <div className="w-full h-28 flex items-center justify-center rounded-[10px] overflow-hidden bg-fourth">
        <div className="flex justify-center text-center">
          {image ? (
            <img className="w-full h-full object-cover" src={image} alt="Uploaded" />
          ) : (
            <div className="hover:cursor-pointer" onClick={handleChoose}>
              <CloudUploadIcon fontSize="large" />
              <p className="font-mont font-semibold text-sm">Upload Image</p>
            </div>
          )}
        </div>
      </div>
      {error && <p className="text-red-500 font-mont text-xs mt-2">{error}</p>}
      <div className="flex mt-2">
        <button
          onClick={handleAddToBanner}
          className={`w-full h-10 bg-third hover:bg-third/90 rounded-[6px] text-white font-mont text-xs font-semibold
            ${image ? '' : 'hover:cursor-not-allowed'}
            `}
          disabled={!image}
        >
          <p>{text}</p>
        </button>
        <button
          onClick={handleDeleteImage}
          className="h-10 px-2 ml-2 bg-fifth hover:bg-fifth/90 rounded-[6px] text-white font-mont text-xs font-semibold"
        >
          <DeleteForeverIcon />
        </button>
        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
          onChange={handleUploadImage}
          hidden
          ref={chooseRef}
        />
      </div>
    </div>
  );
};

export default ImageUpload;
