import React from 'react'
import LandingPage from './components/auth/LandingPage'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/home/Home'
import { Provider } from 'react-redux'
import appStore from './redux/appStore'

const App = () => {
  return (
    <Provider store={appStore}>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/home/*' element={<Home/>}/>
    </Routes>
    </BrowserRouter>
    </Provider>
  )
}

export default App
