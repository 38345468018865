import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDispatch } from "react-redux";
import { deleteImage } from "../../redux/imageSlice";

const ImageShow = ({ src, index }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteImage(index));
  };

  return (
    <div className="relative rounded-[10px]">
      <div
        onClick={handleDelete}
        className="absolute hover:cursor-pointer text-fifth hover:text-fifth/80 bg-white rounded-full -top-3 -right-3"
      >
        <RemoveCircleIcon />
      </div>
      <img
        className="min-w-56 h-32 shadow-custom-light rounded-[10px] object-cover"
        src={src}
        alt=""
      />
    </div>
  );
};

export default ImageShow;
